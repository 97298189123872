<template>
  <div class="body">
    <van-list v-model=item.loading :immediate-check="false" :finished=item.finished
              :finished-text=item.finishedText
              @load="getTabListContent(item)"
              v-if="item.contentList.length > 0">
      <div v-for="(item, index) in item.contentList" :key="index">
        <store-coin-record-item :item-obj="item"/>
      </div>
    </van-list>
    <show-no-data-view v-if="item.showNoData"/>
  </div>
</template>

<script>

import {Api} from "@/utils/net/Api";
import {Toast} from "vant";
import ShowNoDataView from "@/components/ShowNoDataView.vue";
import {Constants} from "@/utils/constants";
import StoreCoinRecordItem from "@/pages/app/power/components/storeCoinRecordItem.vue";

export default {
  components: {StoreCoinRecordItem, ShowNoDataView},
  data() {
    return {
      item: {contentList: []},
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "储存记录"
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.tabList = this.getTabList()
    this.item = this.tabList[0]
    this.getTabListContent(this.item)
  },
  mounted() {
  },
  methods: {
    getTabList() {
      return [
        {
          title: "全部",
          assetType: Constants.X_COIN,
          finished: false,
          showNoData: false,
          pageNo: 1,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        }
      ]
    },
    getTabListContent(item) {
      let tab = item
      if (tab.finished) {
        return;
      }
      let data = {
        pageNo: tab.pageNo,
        pageSize: tab.pageSize,
        type: 0,
      }
      let apiObj = Api.queryStakeOrderList(data);
      if (apiObj) {
        apiObj.then(res => {
          if (res.code === 100) {
            let pageInfo = res.data.pageInfo
            let resData = pageInfo,
                list = resData.list;
            if (resData.isLastPage) { // 没有更多了
              tab.finished = true;
            }
            tab.contentList = tab.contentList.concat(this.handleList(list));
            item.showNoData = item.contentList.length === 0
            tab.pageNo = resData.nextPage;
            tab.loading = false;
          } else {
            Toast(res.msg);
            tab.contentList = [{}, {}, {}]
            tab.showNoData = tab.contentList.length === 0
          }
        })
      }
    },
    handleList(list) {
      // let myList = []
      // list.forEach(item => {
      //   myList.push(Object.assign({}, item))
      // })
      // list.forEach(item => {
      //   myList.push(Object.assign({}, item))
      // })
      // list.forEach(item => {
      //   myList.push(Object.assign({}, item))
      // })
      return list;
    },
  },
}
</script>

<style scoped>
.body {
  background-color: #FFFFFF;
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
}

.item {
  margin-top: var(--20);
  padding-left: var(--30);
  padding-right: var(--30);
  margin-left: var(--30);
  height: var(--66);
  background: transparent;
  border-radius: var(--12);
}

.item-selected {
  background: #FFFFFF;
}

.item-title {
  font-size: var(--30);
  line-height: var(--42);
  color: #666666;
}

.item-title-selected {
  color: #1B1B1B;
  font-weight: bold;
}
</style>
