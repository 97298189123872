<!-- otc首页列表条目 -->
<template>
  <div>
    <div
        class="flex_able_1 flex-directionColumn positionRelative itemClass margin-leftAuto margin-rightAuto">
      <div class="flex_able line-height42 font-weightBold">
        <div class="font-color1B1B1B font-size30 flex_1">
          X币储存数量
        </div>
        <div class="font-size30" style="color:#268DFF;">
          {{itemObj.amount}}
        </div>
      </div>
      <div class="flex_able line-height40 margin-top4">
        <div class="font-color666666 font-size28 flex_1">
          每日火箭值产出数量
        </div>
        <div class="font-size28 font-color1B1B1B">
          {{ itemObj.amountDay }}
        </div>
      </div>
      <div class="flex_able line-height34 margin-top18">
        <div class="font-color999999 font-size24 flex_1">
          {{ itemObj.invalidTime }}到期
        </div>
      </div>
      <div class="lineClass positionAbsolute" style="bottom: 0;"></div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    itemObj: {},
    queryStatus: {}
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.itemClass {
  background: #FFFFFF;
  padding-left: var(--30);
  padding-right: var(--30);
  padding-top: var(--34);
  width: 100vw;
  height: var(--202);
}

.lineClass {
  width: var(--690);
  height: var(--1);
  background: #DDDDDD;
  border-radius: var(--4);
}
</style>
